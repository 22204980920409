* {
  font-family: Poppins, sans-serif;
}

@media(min-width: 769px) {
    .mobileColumnGroup {
      display: none;
    }
  }
  @media(max-width: 769px) {
    .rowHeader, .emptyCell {
      display: none;
    }
  }
  table {
    font-family: 'Poppins', sans-serif;
  }
  .tableContainer {
    overflow: auto;
    overflow-anchor: none;
    position: relative;
  }
  .tableContainer caption {
    height: 0px;
  }
  thead tr {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
  }
  tr th {
    height: 40px;
}
td, th {

    /* border-bottom: 1px solid #2E282AAF; */
}
  .rowHeader, [scope="colgroup"] span {
    /* left: 1rem; */
    position: sticky;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
    table-layout: auto;
  }
  td, th {
    /* padding: 0.25rem; */
    /* min-width: 50vw; */
  }
  .mobileColumnGroup th {
      text-align: left;
  }
  .rowHeader {
    text-align: left;
  }
  .featureCheck {
    color: green;
  }
  .featureX {
    color: red;
  }
  td {
    height: 1px;
  }
  thead.stickyHeader {
    position: fixed;
    z-index: 999;
    overflow-x: scroll;
    top: 0px;
  }
  thead.stickyHeader.atBottom {
    position: absolute;
    z-index: 999;
    bottom: 0px;
    left: 0px !important;
    top: initial;
    width: initial !important;
    overflow-x: clip;
  }
  thead::-webkit-scrollbar {
    height: 0px;
  }
  .horizontal-scroller {
    position: fixed;
    bottom: 0;
    height: 30px;
    overflow: auto;
    overflow-y: hidden;
  }
  .horizontal-scroller-content {
    height: 30px;
  }

  @media(min-width: 769px) {
    tr th {
        height: 60px;
      }
  }