@import "./table.css";
@import "./output.css";
@import "@splidejs/splide/dist/css/splide.min.css";


form.disabled input, form.disabled button,  form.disabled textarea {
    opacity: 0.8;
    pointer-events: none;
}

